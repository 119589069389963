import React from 'react';
import InView from '@shared/InView/InView';
import { css } from '@utils';
import styles from './Section.module.scss';

export default class Section extends React.Component {
  constructor() {
    super();
    this.state = { mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  shouldComponentUpdate(nextProps) {
    const { mounted } = this.state;
    const { currentSection } = this.props;

    if (mounted && nextProps.currentSection !== currentSection) {
      return false;
    }

    return true;
  }

  onInView = inView => {
    const { title, setPageSection } = this.props;
    if (inView && setPageSection) {
      setPageSection(title);
    }
  };

  render() {
    const { title, children, className } = this.props;
    // Root Margin changes the triggering area to just the very middle of the screen
    return (
      <InView onChange={this.onInView} rootMargin="-59.9% 0% -39.9% 0%">
        <div
          id={title}
          className={css(styles.section, className && className)}
          role="group"
          style={{ outline: 'none' }}
          tabIndex="-1"
        >
          {children}
        </div>
      </InView>
    );
  }
}
