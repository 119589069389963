import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Meta from './Meta';

const MetaQuery = props => {
  const title = idx(props, _ => _.title);
  const description = idx(props, _ => _.description);
  const fixedImage = idx(props, _ => _.image.fixed);
  return <Meta title={title} description={description} fixedImage={fixedImage} />;
};

export default MetaQuery;

export const query = graphql`
  fragment Meta on ContentfulMeta {
    title
    description
    image {
      fixed {
        src
        width
        height
      }
    }
  }
`;
