import React from 'react';
import styles from './HamburgerMenu.module.scss';
import { css, isMobile } from '@utils';

class HamburgerMenu extends React.PureComponent {
  constructor() {
    super();
    this.state = { visible: false };
    this.isMobile = false;
    this.ismounted = false;
  }

  componentDidMount() {
    this.ismounted = true;
    this.isMobile = isMobile();
    setTimeout(() => {
      this.setState({ visible: true });
    }, 500);
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

  render() {
    const { active, onClick } = this.props;
    const { visible } = this.state;

    return (
      <button
        aria-label={active ? 'Close Button' : 'Menu Button'}
        type="button"
        className={css(styles.hamburgerMenu, active && styles.active, visible && styles.visible)}
        tabIndex="1"
        onClick={e => {
          return !this.isMobile && onClick(e);
        }}
        onTouchStart={e => {
          return this.isMobile && onClick(e);
        }}
      >
        <div className={css(styles.line, styles.line1, active && styles.active)} />
        <div className={css(styles.line, styles.line2, active && styles.active)} />
      </button>
    );
  }
}

export default HamburgerMenu;
