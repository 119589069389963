const headerData = {
  navLinks: [
    {
      label: 'Media',
      sectionName: 'Media'
    },
    {
      label: 'Story',
      sectionName: 'Story'
    },
    {
      label: 'Vision',
      sectionName: 'Vision'
    },
    {
      label: 'Leadership',
      sectionName: 'Leadership'
    },
    {
      label: 'Brands',
      sectionName: 'Brands'
    },
    {
      label: 'Careers',
      sectionName: 'Careers'
    }
  ]
};

export default headerData;
