const metaData = {
  twitter: '@parallel',
  defaultTitle: 'Parallel',
  defaultDescription: 'Parallel',
  defaultPreviewImage: {
    width: 1200,
    height: 630,
    src: '//localhost:8000/images/share-image.png'
  }
};

export default metaData;
