import React from 'react';
import { css } from '@utils';

export const TwitterIcon = props => {
  const { className } = props;

  return (
    <svg className={css(className)} viewBox="0 0 20 16.255">
      <path
        d="M19.961,4.182a8.334,8.334,0,0,1-2.354.646,4.132,4.132,0,0,0,1.8-2.269,8.469,8.469,0,0,1-2.606.987,4.1,4.1,0,0,0-7.092,2.8,4.2,4.2,0,0,0,.106.937,11.612,11.612,0,0,1-8.45-4.271A4.018,4.018,0,0,0,.812,5.071,4.1,4.1,0,0,0,2.635,8.484,4.087,4.087,0,0,1,.778,7.971v.051a4.1,4.1,0,0,0,3.288,4.022,4.138,4.138,0,0,1-1.843.071A4.114,4.114,0,0,0,6.06,14.962,8.223,8.223,0,0,1,.975,16.716,8.7,8.7,0,0,1,0,16.66,11.662,11.662,0,0,0,6.3,18.5,11.581,11.581,0,0,0,17.963,6.847c0-.174,0-.35-.013-.525A8.28,8.28,0,0,0,20,4.2l-.039-.017Z"
        transform="translate(0 -2.246)"
      />
    </svg>
  );
};

export const FacebookIcon = props => {
  const { className } = props;

  return (
    <svg className={css(className)} viewBox="0 0 18 17.891">
      <path
        d="M18,9A9,9,0,1,0,7.594,17.891V11.6H5.309V9H7.594V7.017a3.176,3.176,0,0,1,3.4-3.5,13.841,13.841,0,0,1,2.015.176V5.906H11.873a1.3,1.3,0,0,0-1.467,1.405V9h2.5l-.4,2.6h-2.1v6.289A9,9,0,0,0,18,9Z"
        transform="translate(0)"
      />
    </svg>
  );
};

export const InstagramIcon = props => {
  const { className } = props;

  return (
    <svg className={css(className)} viewBox="0 0 18 18">
      <path
        d="M9,0C6.555,0,6.25.011,5.29.054A6.636,6.636,0,0,0,3.1.472,4.407,4.407,0,0,0,1.51,1.51,4.391,4.391,0,0,0,.472,3.105,6.617,6.617,0,0,0,.054,5.29C.009,6.25,0,6.555,0,9s.011,2.75.054,3.71A6.64,6.64,0,0,0,.472,14.9,4.414,4.414,0,0,0,1.51,16.49,4.4,4.4,0,0,0,3.1,17.528a6.645,6.645,0,0,0,2.185.419C6.25,17.991,6.555,18,9,18s2.75-.011,3.71-.054a6.66,6.66,0,0,0,2.185-.418,4.6,4.6,0,0,0,2.633-2.633,6.641,6.641,0,0,0,.418-2.185C17.991,11.75,18,11.445,18,9s-.011-2.75-.054-3.71A6.656,6.656,0,0,0,17.528,3.1,4.417,4.417,0,0,0,16.489,1.51,4.385,4.385,0,0,0,14.895.472,6.621,6.621,0,0,0,12.71.054C11.75.009,11.445,0,9,0ZM9,1.62c2.4,0,2.689.012,3.637.053a4.959,4.959,0,0,1,1.67.311,2.962,2.962,0,0,1,1.708,1.708,4.969,4.969,0,0,1,.31,1.67c.043.95.052,1.234.052,3.637s-.011,2.689-.056,3.638a5.065,5.065,0,0,1-.316,1.67,2.858,2.858,0,0,1-.674,1.036,2.808,2.808,0,0,1-1.035.672,5.006,5.006,0,0,1-1.676.31c-.955.043-1.237.052-3.644.052s-2.689-.011-3.644-.056a5.1,5.1,0,0,1-1.677-.316,2.787,2.787,0,0,1-1.034-.674A2.733,2.733,0,0,1,1.947,14.3a5.108,5.108,0,0,1-.315-1.676c-.034-.945-.046-1.237-.046-3.633S1.6,6.3,1.632,5.343a5.1,5.1,0,0,1,.315-1.676,2.668,2.668,0,0,1,.675-1.036,2.662,2.662,0,0,1,1.034-.673,4.981,4.981,0,0,1,1.666-.316C6.278,1.609,6.559,1.6,8.966,1.6L9,1.62ZM9,4.378A4.622,4.622,0,1,0,13.621,9,4.621,4.621,0,0,0,9,4.378ZM9,12a3,3,0,1,1,3-3A3,3,0,0,1,9,12Zm5.885-7.8A1.08,1.08,0,1,1,13.8,3.117,1.081,1.081,0,0,1,14.884,4.2Z"
      />
    </svg>
  );
};

export const LinkedInIcon = props => {
  const { className } = props;

  return (
    <svg className={css(className)} viewBox="0 0 18 18">
      <path
        d="M15.335,15.339H12.67V11.162c0-1-.02-2.278-1.389-2.278-1.39,0-1.6,1.084-1.6,2.2v4.25H7.013V6.75h2.56V7.921h.035a2.809,2.809,0,0,1,2.528-1.387c2.7,0,3.2,1.777,3.2,4.091v4.715ZM4,5.575A1.548,1.548,0,1,1,5.551,4.026,1.546,1.546,0,0,1,4,5.575Zm1.337,9.764H2.666V6.75H5.339ZM16.669,0H1.328A1.312,1.312,0,0,0,0,1.3V16.7A1.312,1.312,0,0,0,1.328,18H16.667A1.317,1.317,0,0,0,18,16.7V1.3A1.317,1.317,0,0,0,16.667,0Z"
      />
    </svg>
  );
};

export const YoutubeIcon = props => {
  const { className } = props;

  return (
    <svg className={css(className)} viewBox="0 0 121.48 85.04">
      <path d="M119,13.28A15.23,15.23,0,0,0,108.21,2.54C98.73,0,60.74,0,60.74,0s-38,0-47.46,2.54A15.21,15.21,0,0,0,2.54,13.28C0,22.75,0,42.52,0,42.52S0,62.29,2.54,71.76A15.21,15.21,0,0,0,13.28,82.5C22.75,85,60.74,85,60.74,85s38,0,47.47-2.54A15.23,15.23,0,0,0,119,71.76c2.53-9.47,2.53-29.24,2.53-29.24S121.48,22.75,119,13.28ZM48.59,60.74V24.3L80.16,42.52Z"/>
    </svg>
  )
}
