import React from 'react';

class InView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { inView: false };
    const { children } = props;
    // Store whether this instance uses render props.    i.e. the 'children' prop is a function rather than jsx.
    this.usesRenderProps = typeof children === 'function';
    this.ismounted = false;
  }

  componentDidMount() {
    const { threshold, rootMargin } = this.props;
    const options = { threshold, rootMargin };
    const inViewCallBack = this.usesRenderProps ? this.renderPropCallback : this.inViewCallback;
    this.observer = new IntersectionObserver(inViewCallBack, options);
    this.observer.observe(this.ref);
    this.ismounted = true;
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

  // handle inView changes by calling onChange prop.
  inViewCallback = observerEntries => {
    const { onChange, triggerOnce, threshold } = this.props;

    // Stop observing this ref if we aren't going to do anything with the result.
    if (!onChange) {
      this.observer.unobserve(this.ref);
      return;
    }

    if (observerEntries[0].intersectionRatio > threshold) {
      if (triggerOnce) {
        this.observer.unobserve(this.ref);
      }
      requestAnimationFrame(() => {
        onChange(true);
      })
    } else {
      requestAnimationFrame(() => {
        onChange(false);
      })
    }
  };

  // handle inView changes by settings inView state and passing it as a render prop.
  renderPropCallback = observerEntries => {
    const { triggerOnce, threshold } = this.props;

    // Only updated state if mounted to avoid memory leaks
    if (!this.ismounted) {
      return;
    }

    if (observerEntries[0].intersectionRatio > threshold) {
      if (triggerOnce) {
        this.observer.unobserve(this.ref);
      }
      if(!this.state.inView) {
        this.setState({ inView: true });
      }
    } else {
      if(this.state.inView) {
        this.setState({ inView: false });
      }
    }
  };

  render() {
    const { children, className } = this.props;
    const { inView } = this.state;

    return (
      <div
        className={className}
        ref={r => {
          this.ref = r;
        }}
      >
        {this.usesRenderProps ? children(inView) : children}
      </div>
    );
  }
}

InView.defaultProps = {
  threshold: 0
};

export default InView;
