import React from 'react';
import ReactDOM from 'react-dom';
import LogoSymbol from '@shared/Logos/LogoSymbol';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import headerData from '@data/headerData';
import HamburgerMenu from '@shared/HamburgerMenu/HamburgerMenu';
import styles from './SimplifiedHeader.module.scss';
import { css, q } from '@utils';

class SimplifiedHeader extends React.PureComponent {
  constructor() {
    super();
    this.state = { portalContainer: null };
  }

  componentDidMount() {
    this.setState({
      portalContainer: q('#layout')
    });
  }

  render() {
    const { currentSection, noCloseButton, center } = this.props;
    const { portalContainer } = this.state;

    return (
      <>
        {portalContainer &&
          ReactDOM.createPortal(
            <header className={css(styles.simplifiedHeader)}>
              <GradientBackground
                className={css(styles.gradientBackground)}
                currentStep={currentSection}
              />
              <div className={css(styles.simplifiedHeaderContent)}>
                <a
                  href="/"
                  
                  className={css(styles.logoSymbol, center && styles.logoSymbolCentered)}
                >
                  <LogoSymbol />
                </a>
                 <a
                  href="/"
                  
                  className={css(styles.close)}
                >
                {!noCloseButton && <HamburgerMenu active />}
                </a>
              </div>
            </header>,
            portalContainer
          )}
      </>
    );
  }
}

export default SimplifiedHeader;
