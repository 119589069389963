import React from 'react';
import { throttle } from 'throttle-debounce';
import styles from './GradientBackground.module.scss';
import { css, constrain } from '@utils';

const GRADIENT_PRIMARY = '#c0fb7b';
const GRADIENT_WELLBEING = '#ff8f35';
const GRADIENT_INNOVATION = '#69e9da';
const GRADIENT_TRANSITION = '#ffe601';

const STARTING_STEP = 'Hero';

const SECTION_GRADIENTS_COLORS = {
  Hero: GRADIENT_PRIMARY,
  Story: GRADIENT_PRIMARY,
  Vision: GRADIENT_WELLBEING,
  Leadership: GRADIENT_INNOVATION,
  Brands: GRADIENT_INNOVATION,
  Careers: GRADIENT_WELLBEING,
  Media: GRADIENT_WELLBEING,
  Contact: GRADIENT_PRIMARY
};

class GradientBackground extends React.PureComponent {
  constructor(props) {
    super(props);
    this.previousStep = STARTING_STEP;

    this.gradientPositionTimeoutId = null;
    this.gradientsList = [...new Array(Object.keys(SECTION_GRADIENTS_COLORS).length - 1)];
    this.ismounted = false;
  }

  componentDidMount() {
    this.ismounted = true;
    window.addEventListener('mousemove', this.onMouseMove);
  }

  onMouseMove = throttle(50, e => {
    const xRatio = e.clientX / window.innerWidth - 0.5;
    if (this.ismounted && this.gradient) {
      this.gradient.style.transform = `translate3d(${-1 * xRatio * 600}px, 0, 0)`;
    }
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentStep } = this.props;
    if (currentStep && nextProps.currentStep !== currentStep) {
      this.updateGradient(nextProps.currentStep, currentStep);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);
  }

  updateGradient = (nextStep, previousStep) => {
    if (!this.ismounted) {
      return;
    }

    // don't update if the sections are the same gradient
    if (SECTION_GRADIENTS_COLORS[nextStep] === SECTION_GRADIENTS_COLORS[previousStep]) {
      return;
    }

    const nextStepIndex = constrain(
      Object.keys(SECTION_GRADIENTS_COLORS).indexOf(nextStep),
      0,
      this.gradientsList.length - 1
    );

    this.gradientsList.forEach((gradient, index) => {
      if (index === nextStepIndex) {
        gradient.style.opacity = '1';
      } else {
        gradient.style.opacity = '0';
      }
    });
  };

  render() {
    const { className } = this.props;

    return (
      <div
        ref={r => {
          this.gradient = r;
        }}
        className={css(styles.gradientBackground, className)}
      >
        {Object.keys(SECTION_GRADIENTS_COLORS).map((section, index) => {
          return (
            <div
              key={`GradientSection__${index}`}
              ref={r => {
                this.gradientsList[index] = r;
              }}
              className={css(styles.section)}
              style={{
                backgroundImage: `linear-gradient(90deg, ${SECTION_GRADIENTS_COLORS[section]}, ${GRADIENT_TRANSITION})`,
                background: `linear-gradient(90deg, ${SECTION_GRADIENTS_COLORS[section]}, ${GRADIENT_TRANSITION})`,
                backgroundSize: `100% 100%`,
                backgroundPosition: `0% 0%`
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default GradientBackground;
