import Bowser from 'bowser';

export const q = function q(selector, context) {
  return (context || document).querySelector(selector);
};

export const qAll = function qAll(selector, context) {
  return (context || document).querySelectorAll(selector);
};

export const getBrowser = () => {
  return Bowser.getParser(window.navigator.userAgent);
};

export const isIE = () => {
  if (typeof window === `undefined`) {
    return false;
  }
  const browser = getBrowser();
  const browserIsIE = !!browser.satisfies({
    'internet explorer': '>0'
  });

  return browserIsIE;
};

export const isMobile = () => {
  if (typeof window === `undefined`) {
    return false;
  }

  const browser = getBrowser();
  const browsers = Object.keys(Bowser.BROWSER_MAP);
  const mobileBrowsers = {};

  // store list of all browsers -- every version
  browsers.forEach((_, index, array) => {
    mobileBrowsers[array[index]] = '>0';
  });

  const browserIsMobile = !!browser.satisfies({
    mobile: mobileBrowsers
  });

  return browserIsMobile;
};

const isString = value => {
  return Object.prototype.toString.call(value) === '[object String]';
};

export const css = (...args) => {
  let stylesList = [];

  args
    .filter(style => !!style) // remove any falsey values from our styles array and join our style classes.
    .forEach(style => {
      if (Array.isArray(style)) {
        stylesList = stylesList.concat(css(...style)); // Use recursion to handle nested array of styles.
      } else if (isString(style)) {
        stylesList.push(style); // Only add strings to our results
      }
    });

  return stylesList.join(' ');
};

export const easeInOutQuad = t => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

export const scrollTo = (destinationOffset, duration = 200) => {
  return new Promise(resolve => {
    const start = window.pageYOffset;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName('body')[0].clientHeight;

    const destinationOffsetToScroll = Math.round(
      documentHeight - destinationOffset < windowHeight
        ? documentHeight - windowHeight
        : destinationOffset
    );

    if ('requestAnimationFrame' in window === false) {
      window.scroll(0, destinationOffsetToScroll);
      resolve();
      return;
    }

    function scroll() {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, (now - startTime) / duration);
      const timeFunction = easeInOutQuad(time);
      window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));

      if (window.pageYOffset === destinationOffsetToScroll) {
        resolve();
        return;
      }

      if(time < 1) {
        requestAnimationFrame(scroll);
      }
    }

    scroll();
  });
};

export const constrain = (n, low, high) => {
  return Math.max(Math.min(n, high), low);
};

// Taken from P5.js https://github.com/processing/p5.js/blob/0.9.0/src/math/calculation.js
export const map = (n, start1, stop1, start2, stop2, withinBounds) => {
  const newval = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
  if (start2 < stop2) {
    return this.constrain(newval, start2, stop2);
  }
  return this.constrain(newval, stop2, start2);
};

export const addZeroPrefix = number => {
  if (number < 10) {
    return `0${number}`;
  }

  return `${number}`;
};

export const truncateString = (string, wordCount, addEllipsis) => {
  const splitString = string.split(' ');
  const ellipsis = addEllipsis ? '...' : '';

  if (wordCount < splitString.length - 1) {
    return `${splitString.slice(0, wordCount).join(' ')}${ellipsis}`;
  }

  return `${string}${ellipsis}`;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// source: https://davidwalsh.name/vendor-prefix
export const prefix = property => {
  if (typeof window === `undefined`) {
    return property;
  }

  const styles = window.getComputedStyle(document.documentElement, '');
  const pre = (Array.prototype.slice
    .call(styles)
    .join('')
    .match(/-(moz|webkit|ms)-/) ||
    (styles.OLink === '' && ['', 'o']))[1];

  // if on IE use lowercase ms
  if (pre === 'ms') {
    return pre + capitalizeFirstLetter(property);
  }

  return pre[0].toUpperCase() + pre.substr(1) + capitalizeFirstLetter(property);
};

export const breakpoint = {
  smallPhone: 375,
  phone: 400,
  phoneWide: 480,
  phablet: 560,
  tabletSmall: 640,
  tablet: 768,
  tabletWide: 1024,
  desktop: 1248,
  desktopWide: 1440
};

export const trackEvent = ({ category, action, label }, extraAttributes = { position: null }) => {
  // Only act if we have gtm dataLayer loaded

  if (!(window && window.dataLayer)) return;

  window.dataLayer.push({
    event: 'gtm.event',
    category: category || '',
    action: action || '',
    label: label || '',
    ...extraAttributes
  });
};

// This function should only be used in gatsby-browser.js for tracking internal (virtual) page views
export const virtualPageView = (url, title) => {
  // Only act if we have gtm dataLayer loaded
  if (!(window && window.dataLayer)) return;

  window.dataLayer.push({
    event: 'VirtualPageview',
    virtualPageURL: url,
    virtualPageTitle: title
  });
};
