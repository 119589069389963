import React from 'react';
import ReactDOM from 'react-dom';
import LogoWordMark from '@shared/Logos/LogoWordMark';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import ScrollContext from '@context/ScrollContext';
import styles from './Footer.module.scss';
import {
  TwitterIcon,
  LinkedInIcon,
  YoutubeIcon,
  InstagramIcon,
  FacebookIcon
} from '../Social/SocialIcons';
import { css, q, isIE, trackEvent } from '@utils';

class Footer extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      portalContainer: null,
      active: false
    };
    this.isIE = false;
    this.isMobile = false;
  }

  UNSAFE_componentWillMount() {
    const { removeScrollCallback } = this.context;
    removeScrollCallback(this.onScroll);
  }

  componentDidMount() {
    const { addScrollCallback } = this.context;
    const layoutEl = q('#layout');

    this.setState({
      portalContainer: layoutEl
    });

    this.isIE = isIE();

    addScrollCallback(this.onScroll);
  }

  onScroll = ({ scrollTop, scrollHeight, windowInnerHeight }) => {
    const { active } = this.state;
    if (scrollHeight < 5000) {
      return;
    }
    if (scrollTop + windowInnerHeight >= scrollHeight - 20) {
      if (!active) {
        this.setState({ active: true });
      }
    } else if (active) {
      this.setState({ active: false });
    }
  };

  onLegalClick = (e, link, label) => {
    e.preventDefault();
    trackEvent({ category: 'Footer', action: 'Link', label });
    window.location.href = link;
  };

  render() {
    const { currentGradientStep } = this.props;
    const { portalContainer, active } = this.state;

    return (
      <>
        {portalContainer &&
          ReactDOM.createPortal(
            <div className={css(styles.footer, active && styles.active)}>
              <GradientBackground
                className={css(this.isIE && styles.gradientBackground)}
                currentStep={currentGradientStep}
              />
              <div className={css(styles.footerContentWrapper)}>
                <div className={css(styles.footerLeftContent)}>
                  <div className={css(styles.logoWordMark)}>
                    <LogoWordMark className={css(styles.svg)} />
                  </div>
                  <div className={css(styles.socialIconsList)}>
                    <a
                      href={'https://twitter.com/live_parallel'}
                      target="_blank"
                      aria-label="Twitter"
                      className={css(styles.socialIcon)}
                    >
                      <TwitterIcon className={css(styles.icon)} />
                    </a>
                    <a
                      href={'https://www.linkedin.com/company/live-parallel'}
                      target="_blank"
                      aria-label="LinkedIn"
                      className={css(styles.socialIcon)}
                    >
                      <LinkedInIcon className={css(styles.icon)} />
                    </a>
                    <a
                      href={'https://www.instagram.com/live_parallel'}
                      target="_blank"
                      aria-label="Instagram"
                      className={css(styles.socialIcon)}
                    >
                      <InstagramIcon className={css(styles.icon)} />
                    </a>
                    <a
                      href={'https://www.facebook.com/WellBeingReimagined/'}
                      target="_blank"
                      aria-label="Facebook"
                      className={css(styles.socialIcon)}
                    >
                      <FacebookIcon className={css(styles.icon)} />
                    </a>
                    <a
                      href={'https://www.youtube.com/channel/UCs3uFnFBadt08qdEfiBAflA'}
                      target="_blank"
                      aria-label="Youtube"
                      className={css(styles.socialIcon)}
                    >
                      <YoutubeIcon className={css(styles.icon)} />
                    </a>
                  </div>
                </div>

                <div className={css(styles.footerRightContent)}>
                  <div className={css(styles.copyRight)}>
                    {`©Parallel ${new Date().getFullYear()}`}
                  </div>
                  <div className={css(styles.links)}>
                    <a
                      className={css(styles.link)}
                      onClick={e => {
                        this.onLegalClick(e, '/terms', 'Terms & Conditions');
                      }}
                      href="/terms"
                    >
                      Terms &amp; Conditions
                    </a>
                    <a
                      className={css(styles.link)}
                      onClick={e => {
                        this.onLegalClick(e, '/privacy', 'Privacy Policy');
                      }}
                      href="/privacy"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>,
            portalContainer
          )}
      </>
    );
  }
}

Footer.contextType = ScrollContext;
export default Footer;
