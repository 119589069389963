import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Contact from './Contact';

const ContactQuery = props => {
  const title = idx(props, _ => _.title);
  const headline = idx(props, _ => _.headline);
  const link = idx(props, _ => _.link);
  const emailsData = idx(props, _ => _.emails);
  const emails = emailsData
    ? emailsData.map(email => {
        const emailFormatted = {};
        emailFormatted.title = idx(email, _ => _.title);
        emailFormatted.email = idx(email, _ => _.email);
        return emailFormatted;
      })
    : [];
  return <Contact title={title} headline={headline} link={link} emails={emails} />;
};

export default ContactQuery;

export const query = graphql`
  fragment Contact on ContentfulContact {
    title
    headline
    emails {
      title
      email
    }
  }
`;
