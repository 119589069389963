import React from 'react';
import { css } from '@utils';

const LogoSymbol = props => {
  const { onClick, className } = props;

  return (
    <svg className={css(className)} viewBox="0 0 15.22 16.4" onClick={onClick}>
      <title>P</title>
      <g>
        <polygon points="0 16.4 0.3 13.19 7.99 13.19 8.35 16.39 0 16.4" />
        <path
          d="M10.07,9.88H.34V6.68h9.73c1.64,0,1.95-.61,1.95-1.74S11.71,3.2,10.07,3.2H.34L0,0h10c3.23,0,5.15,1.85,5.15,4.94S13.3,9.88,10.07,9.88Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
};

export default LogoSymbol;
