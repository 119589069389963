import React from 'react';
import { Helmet } from 'react-helmet';
import metaData from '@data/metaData';

const Meta = ({ title, description, fixedImage }) => {
  const shareTitle = title || metaData.defaultTitle;
  const shareDescription = description || metaData.defaultDescription;

  // ex.  imageObj = {src: "image/src/here.png", width: 666, height: 666}
  const shareImage = fixedImage || metaData.defaultPreviewImage;

  return (
    <Helmet>
      <title>{shareTitle}</title>

      <meta name="description" content={shareDescription} />

      <meta property="og:image" content={`https:${shareImage.src}`} />
      <meta property="og:image:width" content={shareImage.width} />
      <meta property="og:image:height" content={shareImage.height} />
      <meta property="og:title" content={shareTitle} />
      <meta property="og:description" content={shareDescription} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={metaData.twitter} />
      <meta name="twitter:title" content={shareTitle} />
      <meta name="twitter:description" content={shareDescription} />
      <meta name="twitter:image" content={`https:${shareImage.src}`} />
    </Helmet>
  );
};

export default Meta;
