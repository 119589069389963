import React from 'react';
import CharacterReveal from '@shared/Reveals/CharacterReveal';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import { css, trackEvent } from '@utils';
import styles from './Contact.module.scss';

const Email = props => {
  const { title, email } = props;

  const onGetInTouchClick = (e, externalLink, linkTitle) => {
    e.preventDefault();
    trackEvent({ category: 'Get In Touch', action: 'Email', label: `Inquiries - ${linkTitle}` });
    window.location.href = externalLink;
  };

  return (
    <a
      href={`mailto:${email}`}
      className={css(styles.emailLink)}
      onClick={e => {
        onGetInTouchClick(e, `mailto:${email}`, title);
      }}
    >
      <div className={css(styles.emailTitle)}>{title}</div>
      <div className={css(styles.arrowWrapper)}>
        <div className={css(styles.arrow)} />
        <div className={css(styles.arrowHead)}>
          <div className={css(styles.arrowHeadTop)} />
          <div className={css(styles.arrowHeadBottom)} />
        </div>
      </div>
    </a>
  );
};

const Contact = props => {
  const { title, headline, emails } = props;
  const emailElements = emails.map((email, index) => {
    return <Email key={`email-${email.title}-${index}`} title={email.title} email={email.email} />;
  });

  return (
    <div className={css(styles.contact)}>
      <div className={css(styles.left)}>
        <InView triggerOnce>
          {inView => {
            return (
              <>
                <Reveal
                  animateOpacity
                  canPlay={inView}
                  from="bottom"
                  duration={1275}
                  ease="cubic-bezier(0,0.4,0.4,1)"
                  offset="45px"
                >
                  <p className={css(styles.eyebrow, inView && styles.inView)}>{title}</p>
                </Reveal>
                <div className={css(styles.headline, inView && styles.inView)}>
                  <CharacterReveal
                    animateOpacity
                    canPlay={inView}
                    characterOffsetDelay={15}
                    characterWordSpacing="10px"
                    copy={[headline]}
                    from="bottom"
                    delay={500}
                    duration={700}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="25px"
                    multilineMasking={false}
                    multilineOffsetDelay={100}
                  />
                </div>
              </>
            );
          }}
        </InView>
      </div>
      <div className={css(styles.right)}>{emailElements}</div>
    </div>
  );
};

export default Contact;
