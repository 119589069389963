import React from 'react';
import { css } from '@utils';

const LogoWordMark = props => {
  const { onClick, className } = props;
  return (
    <svg className={css(className)} viewBox="0 0 525.16 138.03" onClick={onClick}>
      <title>Parallel</title>
      <g>
        <g>
          <path
            fill="#2e2e2b"
            d="M232.71,42.16a17.85,17.85,0,0,1,4.64.53V63.06a46.72,46.72,0,0,0-9.62-.88C213.3,62.18,206,69.73,206,87.11v48.64H182.44a754.3,754.3,0,0,0,0-91.83h22.44l-.33,18.61h.5C209.86,50.42,218.61,42.16,232.71,42.16ZM171.62,72.54c0,11.76-1.58,41,0,63.21H149.15l-.59-13.69h-.83c-7.63,10.88-14.1,15.62-28,15.62-17.92,0-25.55-9.3-25.55-25.63,0-16.16,8-27.39,37.65-27.39h16.1V68.33c0-8.08-1.5-18.09-14.1-18.09S120.36,61,120.36,69.07v4.87H97.8V71.35C97.8,62.75,100.29,42,135,42,167,42,171.62,55.86,171.62,72.54ZM147.9,93.08H136.28c-15.42,0-18.08,7-18.08,16.86,0,10.36,3.32,15.63,12.61,15.63,10.78,0,17.09-11.24,17.09-26ZM90.18,48.69V50c0,15.38-5,39.27-42.21,39.27h-23l1.29,46.58H0C1.6,91.5,1.79,49.34,0,9.62H49.3C87.19,9.61,90.18,33.49,90.18,48.69Zm-25.76-.11c0-16.66-2.49-29.34-20.61-29.34H24.93V79.72H43.65C60.1,79.72,64.42,70,64.42,50ZM525.16,0H501.58q2.61,67.49,0,135.79h23.58Q522.64,68.17,525.16,0ZM489.6,84.13v6.14H430.54v1.58c0,25.29,3.82,36.88,19.91,36.88,12.49,0,17.07-6.5,17.57-24.58h20.91v1c0,18.44-9.79,32.83-39.81,32.83-37.66,0-43.79-21.94-43.79-46.88v-2.1c0-23.53,7.63-47.24,43.62-47.24C485.28,41.81,489.6,66.39,489.6,84.13Zm-25.38-4.74c0-13.35-1.17-29.5-16.1-29.5-14.1,0-17.58,12.82-17.58,31.61h33.68ZM320.64,72.54c0,11.76-1.58,41,0,63.21H298.17l-.59-13.69h-.83c-7.63,10.88-14.1,15.62-28,15.62-17.92,0-25.55-9.3-25.55-25.63,0-16.16,8-27.39,37.66-27.39h16.09V68.33c0-8.08-1.49-18.09-14.1-18.09-12.44,0-13.44,10.75-13.44,18.83v4.87H246.82V71.35c0-8.6,2.49-29.36,37.16-29.36C316,42,320.64,55.86,320.64,72.54ZM296.92,93.08H285.31c-15.43,0-18.08,7-18.08,16.86,0,10.36,3.31,15.63,12.6,15.63,10.79,0,17.09-11.24,17.09-26ZM333.83,0q2.6,67.49,0,135.79H357.4Q354.88,68.17,357.4,0Zm35.93,0q2.61,67.49,0,135.79h23.58q-2.52-67.62,0-135.79Z"
            transform="translate(0)"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoWordMark;
